import { Link } from "gatsby";
import React from "react";


// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

import './privacy.scss';

const Privacy = (props) => {

  return (
    <Layout>
      <SEO
        title={`Privacy policy`}
      />
      <div className='page privacyPage'>
      <h1 className="privacyPage__heading">
        Privacy policy
      </h1>
      <div className="privacyPage__body">
        <p>
            I respect your privacy, so I try and comply data gathering practices with the General Data Protection Regulations (GDPR). In this Privacy policy, I would like to describe how this website gathers and uses data.
        </p>
        <h2>
            Data Controller
        </h2>
        <p>
            Under the GDPR, I act as the Data Controller. Information about this Privacy policy and related topics can be obtained by writing to <a href="mailto:admin@zdcreatech.com">my email address</a> with the line "Privacy policy" as the subject.
        </p>
        <h2>
            Purpose
        </h2>
        <p>
            The information collected from you on this website and subsequently processed may include your contact details (name, email address etc.) and any other data that is required for the main purposes of enabling me to contact you.
        </p>
        <p>
            Besides that, some of the information might be collected by the analytics tools and other third-party services used on this website via cookies and other related technologies. The process is described in the <Link to="/cookies/">Cookies policy</Link>.
        </p>
        <h2>
            Your legal rights
        </h2>
        <p>
            Under data protection laws you have rights in relation to your personal data that include the right to request access, correction, erasure, restriction, and transfer. 
        </p>
        <p>
            On matters connected to the cases above, you can write to <a href="mailto:admin@zdcreatech.com">my email address</a> with the line "Personal data" as the subject.
        </p>
        <h2>
            Third-party links
        </h2>
        <p>
        This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. I do not control these third-party websites and am not responsible for their privacy statements.
        </p>
        <hr />
        <p>
            By continuing using this website, filling and submitting contact forms, leaving comments under blog posts, and engaging in other forms of activites, you express your full consent with this Privacy policy and data collection. 
        </p>
      </div>
      </div>
    </Layout>
  )
}

export default Privacy;